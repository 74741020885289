<template>
  <div style="padding: 10px">
    <div style="margin: 10px 0">
      <!--<el-button type="primary" @click="exportUser">导出</el-button>-->
      <el-button type="primary" @click="add">新增</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
    </div>
    <!--搜索区域-->
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="userId"
              label="账号"
              :formatter="userIdFormatter"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--              prop="userId"-->
      <!--              label="账号"-->
      <!--      >-->
      <!--        <template v-slot="scope">-->
      <!--          <el-select v-model="scope.row.userId" disabled>-->
      <!--            <el-option-->
      <!--                    v-for="option in userIdArr"-->
      <!--                    :key="option.value"-->
      <!--                    :label="option.label"-->
      <!--                    :value="option.value">-->
      <!--            </el-option>-->
      <!--          </el-select>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
              prop="createTime"
              label="统计日期">
      </el-table-column>
      <el-table-column
              prop="recruit"
              label="招募">
      </el-table-column>
      <el-table-column
              prop="box"
              label="宝箱">
      </el-table-column>
      <el-table-column
              prop="fishGod"
              label="金鱼竿">
      </el-table-column>
      <el-table-column
              prop="gold"
              label="金砖">
      </el-table-column>
      <el-table-column
              prop="towerLevel"
              label="爬塔层数">
      </el-table-column>
      <el-table-column
              prop="pictorial"
              label="图鉴等级">
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" @click="configData(scope.row)">配置</el-button>
          <el-button size="mini" @click="ocr(scope.row)">OCR</el-button>
          <el-button size="mini" @click="compute(scope.row)">计算</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
    <!--新增编辑表单-->
    <el-dialog title="新增/编辑" v-model="dialogVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="账号">
          <el-select v-model="form.userId" placeholder="请选择账号" filterable>
            <el-option
                    v-for="item in userIdArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD" v-model="form.createTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="招募">
          <el-input type="number" v-model="form.recruit"></el-input>
        </el-form-item>
        <el-form-item label="木箱子">
          <el-input type="number" v-model="form.woodBox"></el-input>
        </el-form-item>
        <el-form-item label="青铜箱子">
          <el-input type="number" v-model="form.bronzeBox"></el-input>
        </el-form-item>
        <el-form-item label="黄金箱子">
          <el-input type="number" v-model="form.goldBox"></el-input>
        </el-form-item>
        <el-form-item label="铂金箱子">
          <el-input type="number" v-model="form.platinumBox"></el-input>
        </el-form-item>
        <el-form-item label="黄金鱼竿">
          <el-input type="number" v-model="form.fishGod"></el-input>
        </el-form-item>
        <el-form-item label="金砖">
          <el-input type="number" v-model="form.gold"></el-input>
        </el-form-item>
        <el-form-item label="爬塔层数">
          <el-input type="number" v-model="form.towerLevel"></el-input>
        </el-form-item>
        <el-form-item label="图鉴等级">
          <el-input type="number" v-model="form.pictorial"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
      </template>
    </el-dialog>
    <!--  上传文件  -->
    <el-dialog title="上传" v-model="dialogUpload" width="30%">
      <el-form :model="formUpload">
        <el-form-item style="text-align: center" label-width="0">
          <el-upload
                  class="avatar-uploader"
                  :action="formUpload.url"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogUpload = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: 'towerFormation',
  components: {},
  data() {
    return {
      userIdArr: [
        {value: 1, label: '花猪'},
        {value: 456, label: '鹏'},
        {value: 457, label: '胖冬'},
        {value: 458, label: '1304-83-1'},
        {value: 459, label: '1304-83-2'},
        {value: 460, label: '1304-83-3'},
        {value: 461, label: '4号花猪'},
        {value: 462, label: '3号花猪'},
        {value: 463, label: '5号花猪'},
        {value: 464, label: '十八'},
        {value: 465, label: '种猪'},
        {value: 466, label: 'J、贝壳'},
        {value: 467, label: 'J、黄蜂'},
        {value: 468, label: '1号花猪'},
        {value: 469, label: '2号花猪'},
        {value: 470, label: '4368-1'},
        {value: 471, label: '4368-2'},
        {value: 472, label: '4368-3'},
        {value: 473, label: '1-5984'},
        {value: 474, label: '2-5984'},
        {value: 475, label: '3-5984'},
        {value: 476, label: '6000-1'},
        {value: 477, label: '6000-2'},
        {value: 478, label: '6000-3'},
        {value: 479, label: '5999-1'},
        {value: 480, label: '5999-2'},
        {value: 481, label: '5999-3'}
      ],
      dialogVisible: false,
      dialogUpload: false,
      form: {
        createTime: '',
      },
      formUpload: {
        id: '',
        url: ''
      },
      userId: '',
      loading: true,
      search: {},
      query: {},
      currentPage: 1,
      pageSize: 30,
      total: 0,
      tableData: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    // 点击新增按钮
    add() {
      this.dialogVisible = true
      this.form = {}
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    compute(row) {
      this.$router.push({path: '/saltedFish/goldFishResult', query: {userId: row.userId}}); // 使用查询参数
    },
    configData(row) {
      this.$router.push({path: '/saltedFish/goldFish', query: {userId: row.userId}}); // 使用查询参数
    },
    ocr(row) {
      this.dialogUpload = true;
      this.formUpload.url = "https://www.aseerdd.shop/api/saltedFishSource/ocr?id=" + row.id
      // this.loading = true;
      // request.get("/saltedFishSource/ocr?id=" + row.id).then(res => {
      //   this.$message({
      //     type: "success",
      //     message: res.msg
      //   })
      //   this.load() // 刷新表格的数据
      //   this.loading = false
      // })
    },
    handleAvatarSuccess(res) {
      this.dialogUpload = false;
      this.$message({
        type: "success",
        message: res.msg
      })
      this.load();
      // console.log(res.data);
    },
    save() {
      this.loading = true;
      if (this.form.id) {  // 更新
        request.post("/saltedFishSource/update", this.form).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          })
          this.load() // 刷新表格的数据
          this.loading = false
          this.dialogVisible = false  // 关闭弹窗
        })
      } else {  // 新增
        request.post("/saltedFishSource/save", this.form).then(res => {
          if (res.code !== 0) {
            this.$message({
              type: "error",
              message: res.msg
            })
          } else {
            this.$message({
              type: "success",
              message: res.msg
            })
          }
          this.load() // 刷新表格的数据
          this.loading = false
          this.dialogVisible = false  // 关闭弹窗
        })
      }
    },
    exportUser() {
      location.href = "http://182.92.223.123:9090/saltedFishSource/export";
    },
    resetData() {
      this.search = {}
      this.load()
    },
    load() {
      this.loading = true
      const searchForm = {...this.search}
      let obj = Object.assign(searchForm, this.query)
      let reqData = {
        ...obj
      }
      reqData.current = this.currentPage
      reqData.size = this.pageSize
      request.get("/saltedFishSource/list", {
        params: reqData
      }).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(pageSize) {   // 改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {  // 改变当前页码触发
      this.currentPage = pageNum
      this.load()
    },
    userIdFormatter(row, column, cellValue) {
      for (let i = 0; i < this.userIdArr.length; i++) {
        if (this.userIdArr[i].value === cellValue) {
          return this.userIdArr[i].label;
        }
      }
      return cellValue; // 返回原始值作为默认
    }
  }
}
</script>
